import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

const PlazosEntrega = ({ pieza }) => {
    const getPlazosEnvios = () => {
        const plazos = [];
        const piezas = <small>{t('carrito.resume.piezas')}</small>;
        const paletizados = <small>{t('carrito.resume.paletizados')}</small>;
        const internacional = (
            <small>{t('carrito.resume.internacional')}</small>
        );
        const isPiezaNormal = checkIsPiezaNormal();
        const isPaletizado = checkIsPaletizado();
        const isInternacional = checkIsInternacional();

        if (isPiezaNormal) {
            plazos.push(piezas);
        }
        if (isPaletizado) {
            plazos.push(paletizados);
        }
        if (isInternacional) {
            plazos.push(internacional);
        }

        return plazos;
    };

    const checkIsPiezaNormal = () => {
        if (
            pieza.tipo_pieza_id === 55 || // CAJA CAMBIOS
            pieza.tipo_pieza_id === 406 || // MOTOR COMPLETO
            pieza.tipo_pieza_id === 80 || // CAPOT
            (pieza.tipo_pieza_id >= 146 && pieza.tipo_pieza_id <= 151) || // PUERTAS
            (pieza.tipo_pieza_id >= 174 && pieza.tipo_pieza_id <= 176) ||
            pieza.tipo_pieza_id === 184 || // PORTON
            (pieza.tipo_pieza_id >= 70 && pieza.tipo_pieza_id <= 71) ||
            pieza.tipo_pieza_id === 1083 || // ALETAS
            pieza.tipo_pieza_id === 94 ||
            pieza.tipo_pieza_id === 172 || // PARAGOLPES
            (pieza.tipo_pieza_id >= 219 && pieza.tipo_pieza_id <= 223) ||
            pieza.tipo_pieza_id === 1361 || // TRANSMISIÓN
            pieza.tipo_pieza_id === 206 ||
            pieza.tipo_pieza_id === 479 ||
            pieza.tipo_pieza_id === 480 || // CARDAN
            (pieza.tipo_pieza_id >= 309 && pieza.tipo_pieza_id <= 316) || // ASIENTOS
            pieza.tipo_pieza_id === 498 ||
            pieza.tipo_pieza_id === 499 || // PUENTE
            pieza.tipo_pieza_id === 164 || // CAJA TRASERA
            pieza.tipo_pieza_id === 23 || // PORTAEQUIPAJES
            pieza.tipo_pieza_id === 88 ||
            pieza.tipo_pieza_id === 127 ||
            pieza.tipo_pieza_id === 167 || // FALDONES
            pieza.tipo_pieza_id === 86 || // DEFENSA
            pieza.tipo_pieza_id === 94 ||
            pieza.tipo_pieza_id === 172 || // PARAGOLPES
            pieza.tipo_pieza_id === 183 || // SUBCHASIS
            pieza.tipo_pieza_id === 339 || // KIT AIRBAG
            pieza.tipo_pieza_id === 387 || // DEP. COMBUSTIBLE
            pieza.tipo_pieza_id === 420 || // SILENCIADOR
            (pieza.tipo_pieza_id >= 438 && pieza.tipo_pieza_id <= 441) ||
            pieza.tipo_pieza_id === 1617 || // TUBO ESCAPE
            pieza.tipo_pieza_id === 460 ||
            pieza.tipo_pieza_id === 461 || // BARRA ESTABILIZADORA
            (pieza.tipo_pieza_id >= 1257 && pieza.tipo_pieza_id <= 1259) || // CUNA
            pieza.tipo_pieza_id === 4 ||
            pieza.tipo_pieza_id === 1094 || // BARRAS TECHO
            pieza.tipo_pieza_id === 360 || // SALPICADERO
            pieza.tipo_pieza_id === 105 ||
            pieza.tipo_pieza_id === 106 ||
            pieza.tipo_pieza_id === 361
        )
            return false;
        else return true;
    };

    const checkIsPaletizado = () => {
        if (
            pieza.tipo_pieza_id === 55 || // CAJA CAMBIOS
            pieza.tipo_pieza_id === 406 || // MOTOR COMPLETO
            pieza.tipo_pieza_id === 80 || // CAPOT
            (pieza.tipo_pieza_id >= 146 && pieza.tipo_pieza_id <= 151) || // PUERTAS
            (pieza.tipo_pieza_id >= 174 && pieza.tipo_pieza_id <= 176) ||
            pieza.tipo_pieza_id === 184 || // PORTON
            (pieza.tipo_pieza_id >= 70 && pieza.tipo_pieza_id <= 71) ||
            pieza.tipo_pieza_id === 1083 || // ALETAS
            pieza.tipo_pieza_id === 94 ||
            pieza.tipo_pieza_id === 172 || // PARAGOLPES
            (pieza.tipo_pieza_id >= 219 && pieza.tipo_pieza_id <= 223) ||
            pieza.tipo_pieza_id === 1361 || // TRANSMISIÓN
            pieza.tipo_pieza_id === 206 ||
            pieza.tipo_pieza_id === 479 ||
            pieza.tipo_pieza_id === 480 || // CARDAN
            (pieza.tipo_pieza_id >= 309 && pieza.tipo_pieza_id <= 316) || // ASIENTOS
            pieza.tipo_pieza_id === 498 ||
            pieza.tipo_pieza_id === 499 || // PUENTE
            pieza.tipo_pieza_id === 164 || // CAJA TRASERA
            pieza.tipo_pieza_id === 23 || // PORTAEQUIPAJES
            pieza.tipo_pieza_id === 88 ||
            pieza.tipo_pieza_id === 127 ||
            pieza.tipo_pieza_id === 167 || // FALDONES
            pieza.tipo_pieza_id === 86 || // DEFENSA
            pieza.tipo_pieza_id === 94 ||
            pieza.tipo_pieza_id === 172 || // PARAGOLPES
            pieza.tipo_pieza_id === 183 || // SUBCHASIS
            pieza.tipo_pieza_id === 339 || // KIT AIRBAG
            pieza.tipo_pieza_id === 387 || // DEP. COMBUSTIBLE
            pieza.tipo_pieza_id === 420 || // SILENCIADOR
            (pieza.tipo_pieza_id >= 438 && pieza.tipo_pieza_id <= 441) ||
            pieza.tipo_pieza_id === 1617 || // TUBO ESCAPE
            pieza.tipo_pieza_id === 460 ||
            pieza.tipo_pieza_id === 461 || // BARRA ESTABILIZADORA
            (pieza.tipo_pieza_id >= 1257 && pieza.tipo_pieza_id <= 1259) || // CUNA
            pieza.tipo_pieza_id === 4 ||
            pieza.tipo_pieza_id === 1094 || // BARRAS TECHO
            pieza.tipo_pieza_id === 360 || // SALPICADERO
            pieza.tipo_pieza_id === 105 ||
            pieza.tipo_pieza_id === 106 ||
            pieza.tipo_pieza_id === 361
        )
            return true;
        else return false;
    };

    const checkIsInternacional = () => {
        if (pieza.proveedor?.pais_id > 1) return true;
        else return false;
    };
    return (
        <div className="d-flex flex-row align-items-center mt-md-1 mt-lg-2 resume__each-card-mb--producto--garantia">
            <FontAwesomeIcon
                icon={faTruckFast}
                size="1x"
                className="action me-1"
                color="#215732"
            />

            {getPlazosEnvios()}
        </div>
    );
};

export default PlazosEntrega;
