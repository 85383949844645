import { useRef } from 'react'
import Navbar from "../../shared/components/Navbar"
import './condicionesGenerales.scss'
import { useTranslation } from 'react-i18next'

const CondicionesGenerales = () => {
    const garantiasRef = useRef(null)
    const motoresRef = useRef(null)
    const cajasCambioRef = useRef(null)
    const electricosRef = useRef(null)
    const condicionesRef = useRef(null)
    const plazosRef = useRef(null)
    const danosRef = useRef(null)
    const reservaRef = useRef(null)
    const { t } = useTranslation()

    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }
    }
    return (
        <div className="condiciones-generales w-100 h-100 d-flex flex-column px-4" style={{ overflowY: 'scroll' }}>
            <Navbar />

            <div className="w-100 d-flex flex-column flex-lg-row align-items-start justify-content-center condiciones-generales__container">
                <div className='mt-4 condiciones-generales__container--index'>
                    <ul>
                        <li onClick={() => scrollToSection(garantiasRef)}>{t('condiciones-generales.garantias.title')}</li>
                        <li onClick={() => scrollToSection(condicionesRef)}>{t('condiciones-generales.motores.title')}</li>
                        <li onClick={() => scrollToSection(motoresRef)}>{t('condiciones-generales.cajas-de-cambio.title')}</li>
                        <li onClick={() => scrollToSection(cajasCambioRef)}>{t('condiciones-generales.exclusiones.title')}</li>
                        <li onClick={() => scrollToSection(electricosRef)}>{t('condiciones-generales.condiciones-de-devolucion.title')}</li>
                        <li onClick={() => scrollToSection(plazosRef)}>{t('condiciones-generales.plazo-de-entrega.title')}</li>
                        <li onClick={() => scrollToSection(danosRef)}>{t('condiciones-generales.danos-en-el-transporte.title')}</li>
                        <li onClick={() => scrollToSection(reservaRef)}>{t('condiciones-generales.reserva-de-material-y-paga-senal.title')}</li>
                    </ul>
                </div>
                <div className="col-12 col-lg-8 content-info mt-4 mb-5 condiciones-generales__container--info">
                    <h1>{t('condiciones-generales.title')}</h1>
                    {/* Garantias */}
                    <h2 ref={garantiasRef}>{t('condiciones-generales.garantias.title')}</h2>
                    <p>
                        <strong className='me-2'>{t('condiciones-generales.garantias.text.1')}</strong>
                        <span>{t('condiciones-generales.garantias.text.2')}</span>
                    </p>
                    <p>{t('condiciones-generales.garantias.text.3')}</p>
                    <p>
                        {t('condiciones-generales.garantias.text.4')}
                        <strong>{t('condiciones-generales.garantias.text.5')}</strong>
                        {t('condiciones-generales.garantias.text.6')}
                        <strong>{t('condiciones-generales.garantias.text.7')}</strong>
                        {t('condiciones-generales.garantias.text.8')}
                    </p>
                    <p>{t('condiciones-generales.garantias.text.9')}</p>
                    {/* Motores */}
                    <h2 ref={condicionesRef}>{t('condiciones-generales.motores.title')}</h2>
                    <p>{t('condiciones-generales.motores.text.1')}</p>
                    <p className='ms-3'>- <strong>{t('condiciones-generales.motores.text.2')}</strong></p>
                    <p className='ms-3'>- <strong>{t('condiciones-generales.motores.text.3')}</strong></p>
                    <p className='ms-3'>- {t('condiciones-generales.motores.text.4')}</p>
                    <p>{t('condiciones-generales.motores.text.5')}</p>
                    {/* Cajas de cambio */}
                    <h2 ref={motoresRef}>{t('condiciones-generales.cajas-de-cambio.title')}</h2>
                    <p>{t('condiciones-generales.cajas-de-cambio.text.1')}</p>
                    {/* Exclusiones */}
                    <h2 ref={cajasCambioRef}>{t('condiciones-generales.exclusiones.title')}</h2>
                    <p>{t('condiciones-generales.exclusiones.text.1')}</p>
                    <p>{t('condiciones-generales.exclusiones.text.2')}</p>
                    <p>{t('condiciones-generales.exclusiones.text.3')}</p>
                    <p>{t('condiciones-generales.exclusiones.text.4')}</p>
                    {/* Condicion de devolucion */}
                    <h2 ref={electricosRef}>{t('condiciones-generales.condiciones-de-devolucion.title')}</h2>
                    <p>
                        <span>{t('condiciones-generales.condiciones-de-devolucion.text.1')}</span>
                        <strong className='ms-2 me-2'>{t('condiciones-generales.condiciones-de-devolucion.text.2')}</strong>
                        <span>{t('condiciones-generales.condiciones-de-devolucion.text.3')}</span>
                    </p>
                    <p>{t('condiciones-generales.condiciones-de-devolucion.text.4')}</p>
                    <p>
                        <span>{t('condiciones-generales.condiciones-de-devolucion.text.5')}</span>
                        <strong className='ms-2'>{t('condiciones-generales.condiciones-de-devolucion.text.6')}</strong>
                        <span>{t('condiciones-generales.condiciones-de-devolucion.text.7')}</span>
                    </p>
                    {/* Plazo de entrega */}
                    <h2 ref={plazosRef}>{t('condiciones-generales.plazo-de-entrega.title')}</h2>
                    <p>{t('condiciones-generales.plazo-de-entrega.text.1')}</p>
                    {/* Daños en el transporte */}
                    <h2 ref={danosRef}>{t('condiciones-generales.danos-en-el-transporte.title')}</h2>
                    <p>{t('condiciones-generales.danos-en-el-transporte.text.1')}</p>
                    <p>{t('condiciones-generales.danos-en-el-transporte.text.2')}</p>
                    {/* Reserva de material y paga señal */}
                    <h2 ref={reservaRef}>{t('condiciones-generales.reserva-de-material-y-paga-senal.title')}</h2>
                    <p>{t('condiciones-generales.reserva-de-material-y-paga-senal.text.1')}</p>
                    <p>{t('condiciones-generales.reserva-de-material-y-paga-senal.text.2')}</p>
                    {/* No conforme */}
                    <p className='mt-5'>
                        <b>{t('condiciones-generales.no-conforme')}</b>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CondicionesGenerales
