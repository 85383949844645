import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchEnvios } from "../../redux/envios";
import "./seguimiento.scss";
import Envios from "./components/Envios";
import Loading from "../Checkout/Checkout/components/shared/components/Loading";
import Navbar from "../shared/components/Navbar";
import "./seguimiento.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

const Seguimiento = () => {
    const dispatch = useDispatch();
    const { checkoutId } = useParams();
    const { t } = useTranslation();
    const enviosInfo = useSelector(state => {
        return state.envios.entity
    });
    const enviosStatus = useSelector(state => {
        return state.envios.status
    });

    useEffect(() => {
        dispatch(fetchEnvios(checkoutId));
    }, []);

    
    return (
        <div className="w-100 h-100" style={{ overflowY: 'auto'}}>
            <Navbar />

            <div className="container h-100">
                { (enviosStatus.loading === 'succeed') ?
                    <Envios enviosInfo={enviosInfo} /> :
                    (enviosStatus.loading === 'rejected') ?
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center mb-5">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="action" color="#dc3545" />
                            <h3 className="text-uppercase fw-bold mx-3">{t('seguimiento.not-found')}</h3>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="action" color="#dc3545" />
                        </div>
                    </div>
                    :
                    <Loading />
                }
            </div>
        </div>
    )
    
}

export default Seguimiento