import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EnvioItem from "./components/EnvioItem";
import { useTranslation } from 'react-i18next';

const Envios = ({enviosInfo}) => {
    const { t } = useTranslation()
    
    return (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center pb-4 seguimiento">
            <div className="col-12">
                <div className="w-100">
                    <h6 className="fw-bold text-uppercase">{t('seguimiento.envios.direccion')}</h6>

                    <div className="direccion d-flex flex-column mt-2">
                        <small>{enviosInfo.direccion_entrega.nombre}</small>
                        <small>{enviosInfo.direccion_entrega.telefono}</small>
                        <small>{enviosInfo.direccion_entrega.direccion}</small>
                        <small>
                            {enviosInfo.direccion_entrega.codigo_postal} &nbsp;
                            {enviosInfo.direccion_entrega.poblacion}, &nbsp;
                            {enviosInfo.direccion_entrega.area}, &nbsp;
                            {enviosInfo.direccion_entrega.pais?.nombre}
                        </small>
                    </div>
                </div>

                <div className="w-100 mt-3">
                    { enviosInfo.envios?.length > 1 ?
                        <div className="w-100 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" /> 
                                <span className="mx-2 text-uppercase">{t('seguimiento.envios.distintos')}</span>
                            <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" /> 
                        </div>
                        : ''
                    }

                    <div className="seguimiento-envio">
                        <h3 className='fw-bold text-uppercase'>{enviosInfo.envios?.length} {enviosInfo.envios?.length > 1 ? t('seguimiento.envios.paquetes') : t('seguimiento.envios.paquete')}</h3>
                        
                        { enviosInfo.envios?.map((envio, i) => {
                            return (
                                <EnvioItem 
                                    key={'envio-' +i}
                                    envio={envio}
                                />
                            )  
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Envios