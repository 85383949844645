import { faFileArrowDown, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './resume.scss';
import Image from './Image';
import { t } from 'i18next';
import useScreenWidth from '../../../../../../../../hooks/useScreenWidth';
import { generatePresupuestoPDF } from '../../../../../../../../shared/services/checkout';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../shared/components/Spinner';
import { Tooltip } from '@mui/material';
import PlazosEntrega from '../PlazosEntrega';

export default function Resume({
    checkoutInfo
}) {
    const [isLoading, setIsLoading] = useState(false);
    const screenWidth = useScreenWidth();

    const checkComponenteElectrico = () => {
        return checkoutInfo.presupuesto.piezas.find(pieza => {
            if(pieza.tipo_pieza_id === 61 || pieza.tipo_pieza_id === 226 ||
                pieza.tipo_pieza_id === 230 || pieza.tipo_pieza_id === 233 ||
                pieza.tipo_pieza_id === 234 || pieza.tipo_pieza_id === 235 ||
                pieza.tipo_pieza_id === 236 || pieza.tipo_pieza_id === 237 ||
                pieza.tipo_pieza_id === 238 || pieza.tipo_pieza_id === 239 ||
                pieza.tipo_pieza_id === 240 || pieza.tipo_pieza_id === 241 ||
                pieza.tipo_pieza_id === 242 || pieza.tipo_pieza_id === 243 ||
                pieza.tipo_pieza_id === 244 || pieza.tipo_pieza_id === 245 ||
                pieza.tipo_pieza_id === 246 || pieza.tipo_pieza_id === 247 ||
                pieza.tipo_pieza_id === 248 || pieza.tipo_pieza_id === 249 ||
                pieza.tipo_pieza_id === 251 || pieza.tipo_pieza_id === 261 ||
                pieza.tipo_pieza_id === 265 || pieza.tipo_pieza_id === 266 ||
                pieza.tipo_pieza_id === 267 || pieza.tipo_pieza_id ===  450
            ) return true;
            else return false;
        });
    }

    const isMotor = (tipoPieza) => tipoPieza === 406;
    const isPuertasAndPortones = (tipoPieza) => (tipoPieza >= 146 && tipoPieza <= 151) || (tipoPieza >= 174 && tipoPieza <= 176)
    const isComponenteElectronico = (tipoPieza) => (tipoPieza >= 233 && tipoPieza <= 249) || (tipoPieza >= 1192 && tipoPieza <= 1214) || (tipoPieza >= 265 && tipoPieza <= 267) || (tipoPieza >= 1411 && tipoPieza <= 1417)
    const isCremalleraDireccion = (tipoPieza) => tipoPieza === 208;

    const downloadPdf = async () => {
        if(isLoading) return;
        setIsLoading(true);
        const pdfResult = await generatePresupuestoPDF(checkoutInfo.presupuesto.id);

        if(pdfResult) {
            const url = window.URL.createObjectURL(new Blob([pdfResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', checkoutInfo.presupuesto.numero + '.pdf');
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error(t('carrito.resume.download-fail'))
        }

        setIsLoading(false);
    }
    return (
        <div className='w-100 resume'>
            <h1 className="fw-bold mt-2 resume__title">
                {checkoutInfo.presupuesto.ecommerce 
                    ? t('carrito.resume.tu-carrito')
                    : <>{checkoutInfo.presupuesto.numero} <p onClick={downloadPdf}>
                            {isLoading 
                                ? <Spinner className='spinner-download-pdf' />
                                : <Tooltip 
                                    title={t('carrito.resume.download')}
                                    slotProps={{
                                        tooltip: {
                                          sx: {
                                            color: "#525252",
                                            backgroundColor: "#ffff",
                                            boxShadow: '1px 1px 2px #525252'
                                          },
                                        },
                                      }}
                                    >
                                    <FontAwesomeIcon icon={faFileArrowDown} />
                                </Tooltip>}
                        </p></>}
            </h1>

            <div className='w-100 d-flex'>
                {screenWidth >= 1200 
                ? 
                <div className='col-12 d-flex'>
                    <div className='table-responsive flex-grow-1 pb-2'>
                        <table className='table table-stripped resume__desk-table'>
                            <thead>
                                <tr>
                                    <th className='resume__titles-desk'>{t('carrito.resume.producto')}</th>
                                    <th className='resume__titles-desk text-center'>{t('carrito.resume.precio')}</th>
                                </tr>
                            </thead>

                            <tbody>
                                { checkoutInfo.presupuesto?.piezas.map(pieza => {
                                    const row = [];

                                    const piezaRow = 
                                        <tr className='resume__each-desk-row--all-row'  key={'piezas-' + pieza.id}>
                                            <td className='resume__each-desk-row--left'>
                                                <div className='w-100 d-flex align-items-stretch'>
                                                    <div className='img resume__img-desk'>
                                                        <span className='resume__img-desk--span'>
                                                            <Image img={pieza.img} className='resume__img-desk--span--img' />
                                                        </span>
                                                    </div>

                                                    <div className='flex-grow-1'>
                                                        <p className='resume__nombre-pieza-desk'>{pieza?.nombre}</p>
                                                        <small>{pieza?.marca} {pieza?.modelo}</small>

                                                        <div className='w-100 d-flex flex-wrap'>
                                                            { (checkoutInfo.cliente?.mostrar_referencia == true) ?
                                                                <p className="table-tag me-2 mt-2 resume__labels-desk">
                                                                    {t('carrito.resume.ref').toUpperCase()} <span className="fw-bold">{pieza?.referencia_pieza}</span>
                                                                </p>
                                                                : null
                                                            }

                                                            <p className="table-tag mt-2 resume__labels-desk">
                                                                {t('carrito.resume.garantia').toUpperCase()} <span className="fw-bold">{pieza?.tipo_garantia.duracion}</span>
                                                            </p>
                                                        </div>

                                                        { (pieza.observaciones && pieza.observaciones !== "" && pieza.observaciones !== null) &&
                                                            <div className='w-100 mt-3'>
                                                                <small> 
                                                                    <span className='fw-bold'>{t('carrito.resume.observaciones')}</span> {pieza.observaciones}
                                                                </small>
                                                            </div>
                                                        }

                                                        { (isMotor(pieza.tipo_pieza_id) || 
                                                            isPuertasAndPortones(pieza.tipo_pieza_id) || 
                                                            isComponenteElectronico(pieza.tipo_pieza_id) || 
                                                            isCremalleraDireccion(pieza.tipo_pieza_id)
                                                        ) &&
                                                            <div className='w-100 mt-3'>
                                                                
                                                                    { (isMotor(pieza.tipo_pieza_id)) &&
                                                                        <small>{t('carrito.resume.motores.1')} <b>{t('carrito.resume.motores.2')}</b>, {t('carrito.resume.motores.3')}</small>
                                                                    }
                                                                    { (isPuertasAndPortones(pieza.tipo_pieza_id)) &&
                                                                        <small>{t('carrito.resume.puertas.1')} <b>{t('carrito.resume.puertas.2')}</b> {t('carrito.resume.puertas.3')} <b>{t('carrito.resume.puertas.4')}</b>: {t('carrito.resume.puertas.5')}</small>
                                                                    }
                                                                    { (isComponenteElectronico(pieza.tipo_pieza_id)) &&
                                                                        <small>{t('carrito.resume.modulos.1')} <b>{t('carrito.resume.modulos.2')}</b> {t('carrito.resume.modulos.3')}</small>
                                                                    }
                                                                    { (isCremalleraDireccion(pieza.tipo_pieza_id)) &&
                                                                        <small>{t('carrito.resume.cremallera.1')} <b>{t('carrito.resume.cremallera.2')}</b>, {t('carrito.resume.cremallera.3')}</small>
                                                                    }
                                                                
                                                            </div>
                                                        }
                                                        <PlazosEntrega pieza={pieza} />
                                                    </div>
                                                </div>
                                            </td>

                                            <td className='text-center fw-bold'>
                                                {pieza?.unidades > 1 && `${pieza?.unidades} x `}
                                                {(pieza?.precio_venta).toFixed(2)}€
                                            </td>

                                        </tr>;
                                        row.push(piezaRow);

                                        if(pieza?.casco > 0) {
                                            const cascoRow = 
                                                <tr className='resume__each-desk-row--all-row'  key={'casco-' + pieza.id}>
                                                    <td className='resume__each-desk-row--left'>
                                                        <div className='w-100 d-flex align-items-stretch'>
                                                            <div className='img resume__img-desk'>
                                                                <span className='resume__img-desk--span'>
                                                                    <Image img={pieza?.img} className='resume__img-desk--span--img' />
                                                                </span>
                                                            </div>

                                                            <div className='flex-grow-1'>
                                                                <p className='resume__nombre-pieza-desk'>{t('carrito.resume.casco')} {pieza?.nombre}</p>
                                                                <small>{pieza?.marca} {pieza?.modelo}</small>

                                                                <div className='w-100 d-flex flex-wrap'>
                                                                    { (checkoutInfo.cliente?.mostrar_referencia == true) ?
                                                                        <p className="table-tag me-2 mt-2 resume__labels-desk">
                                                                            {t('carrito.resume.ref').toUpperCase()} <span className="fw-bold">{pieza?.referencia_pieza}</span>
                                                                        </p>
                                                                        : null
                                                                    }
                                                                    <p className="table-tag mt-2 resume__labels-desk">
                                                                        {t('carrito.resume.garantia').toUpperCase()} <span className="fw-bold">{pieza?.tipo_garantia?.duracion}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td className='text-center fw-bold'>
                                                        {pieza?.unidades > 1 && `${pieza?.unidades} x `}
                                                        {pieza?.casco}€
                                                    </td>

                                                </tr>;
                                            row.push(cascoRow);
                                        }

                                    return (row);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="col-12 d-flex product-list-mobile pb-3 resume">
                    <p className='resume__titles'><span>{t('carrito.resume.producto')}</span><span className='resume__titles--center'>{t('carrito.resume.unidades')}</span><span className='resume__titles--center'>{t('carrito.resume.precio')}</span></p>
                        { checkoutInfo.presupuesto?.piezas.map(pieza => {
                            const row = [];
                            const piezaRow = 
                                    <div className="resume__each-card-mb" key={'piezas-' + pieza.id}>
                                        <div className='resume__each-card-mb--foto'>
                                            <span className='resume__each-card-mb--foto--span'>
                                                <Image img={pieza.img} className='resume__each-card-mb--foto--span--img' />
                                            </span>
                                        </div>
                                        <div className='resume__each-card-mb--producto'>
                                            <p className='resume__each-card-mb--producto--nombre-pieza'>{pieza?.nombre}</p>
                                            { (checkoutInfo.cliente?.mostrar_referencia == true) ?
                                                <p className='resume__each-card-mb--producto--garantia'><span className='fw-bold'>{t('carrito.resume.ref')}</span> {pieza?.referencia_pieza?.toUpperCase()}</p>
                                                : null
                                            }
                                            <p className='resume__each-card-mb--producto--garantia'>{t('carrito.resume.garantia')} {pieza?.tipo_garantia?.duracion?.toUpperCase()}</p>
                                            { (pieza.observaciones !== "" && pieza.observaciones !== null) &&
                                                <p className='resume__each-card-mb--producto--garantia'><span className='fw-bold'>{t('carrito.resume.observaciones')}</span> {pieza.observaciones}</p>
                                            }
                                            <PlazosEntrega pieza={pieza} />
                                        </div>
                                        <span className='resume__each-card-mb--precio fw-bold'>
                                            {pieza?.unidades > 1 && `${pieza.unidades} x `}
                                            {(pieza?.precio_venta).toFixed(2)}€
                                        </span>
                                    { (isMotor(pieza.tipo_pieza_id) || 
                                            isPuertasAndPortones(pieza.tipo_pieza_id) || 
                                            isComponenteElectronico(pieza.tipo_pieza_id) || 
                                            isCremalleraDireccion(pieza.tipo_pieza_id)
                                            ) &&
                                            <div className='w-100 p-2 resume__each-card-mb--extra-info'>
                                            { (isMotor(pieza.tipo_pieza_id)) &&
                                                <small>{t('carrito.resume.motores.1')} <b>{t('carrito.resume.motores.2')}</b>, {t('carrito.resume.motores.3')}</small>
                                            }
                                            { (isPuertasAndPortones(pieza.tipo_pieza_id)) &&
                                                <small>{t('carrito.resume.puertas.1')} <b>{t('carrito.resume.puertas.2')}</b> {t('carrito.resume.puertas.3')} <b>{t('carrito.resume.puertas.4')}</b>: {t('carrito.resume.puertas.5')}</small>
                                            }
                                            { (isComponenteElectronico(pieza.tipo_pieza_id)) &&
                                                <small>{t('carrito.resume.modulos.1')} <b>{t('carrito.resume.modulos.2')}</b> {t('carrito.resume.modulos.3')}</small>
                                            }
                                            { (isCremalleraDireccion(pieza.tipo_pieza_id)) &&
                                                <small>{t('carrito.resume.cremallera.1')} <b>{t('carrito.resume.cremallera.2')}</b>, {t('carrito.resume.cremallera.3')}</small>
                                            }
                                        </div>
                                    }
                                    </div>
                                
                                row.push(piezaRow);

                                if(pieza.casco > 0) {
                                    const cascoRow = 
                                        <div className="resume__each-card-mb" key={'casco-' + pieza?.id}>
                                            <div className='resume__each-card-mb--foto'>
                                                <span className='resume__each-card-mb--foto--span'>
                                                    <Image img={pieza?.img} className='resume__each-card-mb--foto--span--img' />
                                                </span>
                                            </div>
                                            <div className='resume__each-card-mb--producto'>
                                                <p className='resume__each-card-mb--producto--nombre-pieza'>{t('carrito.resume.casco')} {pieza?.nombre}</p>
                                                { (checkoutInfo.cliente?.mostrar_referencia == true) ?
                                                    <p className='resume__each-card-mb--producto--garantia'><span className='fw-bold'>{t('carrito.resume.ref')}</span> {pieza?.referencia_pieza?.toUpperCase()}</p>
                                                    : null
                                                }
                                                <p className='resume__each-card-mb--producto--garantia'>{t('carrito.resume.garantia')} {pieza?.tipo_garantia.duracion?.toUpperCase()}</p>
                                            </div>
                                            <span className='resume__each-card-mb--unidades fw-bold'>x{pieza?.unidades}</span>
                                            <span className='resume__each-card-mb--precio fw-bold'>{pieza?.casco}€</span>
                                        </div>
                                    row.push(cascoRow);
                                }

                            return (row);
                        })}
                </div>
                }
            </div>

            {
                checkComponenteElectrico() ?
                    <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
                        <div className='flex-grow-1 d-flex flex-column'>
                            <div>
                                        <small className='d-block pe-4'>
                                            <b>{t('carrito.resume.no-garantia.1')}</b>
                                            , {t('carrito.resume.no-garantia.2')}
                                        </small>
                            </div>
                        </div>
                    </div>
                : ''
            }
        </div>
    );
}